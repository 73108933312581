import $ from 'jquery';

$(document).ready(function () {
    var buttons = document.querySelectorAll('*[id^="btnToggle"]');
    let lists = document.getElementsByClassName('liste');

    if (buttons) {
        for (let btnText of buttons) {
            let number = btnText.id.split('-')[1];
            let moreText = document.getElementById(`more-${number}`);
            btnText.onclick = () => {
                if (moreText && btnText) {
                    if (moreText.style.display === "none") {
                        btnText.innerHTML = "Réduire";
                        moreText.style.display = "inline";
                        for (let l of lists) {
                            l.style.height = 'auto';
                        }
                    } else {
                        btnText.innerHTML = "Afficher la suite";
                        moreText.style.display = "none";
                        for (let l of lists) {
                            l.style.height = '100%';
                        }
                    }
                }
            }
        }
    }
});