import $ from 'jquery';

$(document).ready(function () {
    let gform = document.getElementById("gform_login_widget-2");
    let input1, input2;

    if(gform) {
        input1 = gform.querySelector("#input_1");
        input2 = gform.querySelector("#input_2");
    }

    if(input1 && input2) {
        input1.placeholder = "Adresse email *";
        input2.placeholder = "Mot de passe *";
    }
})